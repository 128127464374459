#contatti-page {
    .maps-form-container {
        .dark-overlay {
            background: rgba(0, 0, 0, .75);
            text-align: center;
            padding: 45px 0 66px 0;
            opacity: 1;
            -webkit-transition: opacity .25s ease;
          }
        .form-container {
            .form-box {
                padding: 3rem 2.5rem 1rem 2.5rem;
                position: relative;
                .form-group {
                    padding-top: 1rem;
                    .form-control {
                        border: none;
                        border-bottom: 1px solid $dark;
                        border-radius: 0;
                    }
                }
                .submit-btn{
                    position: absolute;
                    right: -12px;
                    .btn{
                        padding: 1rem 3rem;
                    }
                }
            }
            .form-check{
                a{
                    text-decoration: underline;
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    #contatti-page {
        .maps-form-container {
            position: relative;
            .form-container {
                position: absolute;
                width: 35rem;
                top: 0;
                left: 10rem;
                background-color: white;
                border-left: 10px solid $secondary;
                -webkit-box-shadow: 4px 0px 9px -1px rgba(0,0,0,0.58); 
                box-shadow: 4px 0px 9px -1px rgba(0,0,0,0.58);
                .form-box {

                }
            }
        }
        .contatti{
            margin-top: 10rem;
        }
    }
}
