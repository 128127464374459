#news-page {
    .search-container{
        border-left: 2px solid $secondary;
        margin-bottom: 4rem;
        .input-group{
            padding: 0 10px;
            .form-control{
                border: none;
                border-radius: 0;
                border-bottom: 1px solid $dark;

            }
        }
    }
    .years-container {
        border-left: 2px solid $secondary;
        padding: 1rem;
        .input-group{
            padding: 0 10px;
        }
        .list-unstyled {
            li {
                padding: 1rem;
                margin-top: 1rem;
                margin-bottom: 1rem;
                background-color: white;
                box-shadow: $base_shadow;
                -webkit-box-shadow: $base_shadow;
                -moz-box-shadow: $base_shadow;

                i{
                    color: $secondary
                }
            }
        }
    }
    .news-container{
        margin-top: 4rem;
        .news-item{
            .card{
                border-left: 4px solid $secondary;
            }
        }
    }
}
@include media-breakpoint-up(sm) {
    #news-page {
        .news-container{
            margin-top: -1rem;
        }
    }
}
// News details
#news-detail-page {
   .news-container{
        padding: 0;
        .card{
            padding: 1rem;
            box-shadow: $base_shadow;
            -webkit-box-shadow: $base_shadow;
            -moz-box-shadow: $base_shadow;
            border-left: 4px solid $secondary;
            border-radius: 0;
            .card-img-top{
                border-radius: 0;
            }
        }
   }
}
@include media-breakpoint-up(sm) {
    #news-detail-page {
   
    }
}
