.c-spacer {
    padding-top: 5vh;
}

.grecaptcha-badge {
    visibility: hidden;
}
.alert-success {
    color: $primary;
    // background-color: $white;
    border: 0;
    border-radius: 0;
    border-top: 3px solid $primary;
    font-weight: bold;
}

a {
    text-decoration: none;
}

.pagination {
    text-align: center;
    .page-item {
        .page-link {
            border: none;
            border-radius: 30px;
            margin: auto 10px;
            font-weight: bold;
            width: 50px;
            height: 50px;
            align-content: c;
            padding: 12px 21px;
            box-shadow: $base_shadow;
            -webkit-box-shadow: $base_shadow;
            -moz-box-shadow: $base_shadow;
        }
        &.active {
            .page-link {
                background-color: $secondary;
                box-shadow: none;
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
            }
        }
        &.disabled {
            display: none;
        }

        &:last-child,
        &:first-child {
            .page-link {
                color: $secondary;
                box-shadow: none;
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
            }
        }
    }
}
