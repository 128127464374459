#lavora-con-noi-page {
  .header{
    img{
      max-height: 300px;
      object-fit: cover;
    }
  }

  form{

    .form-control,
    .form-select{
      border-radius: 0;
    }
  }
}

