#azienda-page {
    .quote {
        .display-1.top {
            line-height: 0;
            margin-top: 50px;
        }
        figure {
            figcaption {
                color: $secondary;
            }
        }
    }
    .timeline-container {
        ul.timeline {
            list-style-type: none;
            position: relative;
            &:before {
                content: " ";
                background: $primary;
                display: inline-block;
                position: absolute;
                left: 29px;
                width: 2px;
                height: 100%;
                z-index: 400;
            }
            li {
                margin: 40px 0;
                padding-left: 20px;
                &:before {
                    content: " ";
                    background: $secondary;
                    display: inline-block;
                    position: absolute;
                    border-radius: 50%;
                    border: 3px solid $secondary;
                    left: 20px;
                    width: 20px;
                    height: 20px;
                    z-index: 400;
                }
                .title {
                    text-transform: uppercase;
                    color: $secondary;
                    font-weight: bold;
                }
                .item-c {
                    .item-img {
                        max-width: 100%;
                        height: auto;
                    }
                    .custom-button {
                        padding: 2rem 0.7rem;
                        color: $light;
                        background-color: $secondary;
                        position: relative;
                        .button-text{
                            padding: 10px 80px 10px 0;
                        }
                        .number {
                            background-color: white;
                            position: absolute;
                            width: 125px;
                            height: 125px;
                            border-radius: 125px;
                            right: -57px;
                            .title {
                              color: $primary;
                              position: relative;
                              top: 20px;
                              left: 10px;
                              font-weight: bold;
                              font-size: 4.5rem;
                            }
                        }
                    }
                }
            }
            .certificazioni{
              li {

                padding-left: 30px;
                &:before {
                    content: " ";
                    background: white;
                    display: inline-block;
                    position: absolute;
                    border-radius: 50%;
                    border: 3px solid $secondary;
                    left: 0px;
                    width: 20px;
                    height: 20px;
                    z-index: 400;
                }
              }
            }
        }
    }
}
@include media-breakpoint-up(md) {
    #azienda-page {
        .timeline-container {
            ul.timeline {
                li {
                    display: flex;
                    .title {
                        text-transform: uppercase;
                        color: $secondary;
                        font-weight: bold;
                        width: 15rem;
                    }
                    .item-c {
                        width: 100%;
                        .item-img {
                            max-width: inherit;
                            height: inherit;
                        }
                    }
                }
                .item-c{
                    .flex-fill{
                        width: 50%;
                    }
                    .item-img{
                        width: 100%;
                    }
                }
            }
        }
    }
}
